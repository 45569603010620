body{
    font-family: lato;
}

nav.navbar{
    border-bottom:5px solid #78a12f;
}

.container{
    max-width:1540px;
}


.wrapper{
    margin-top:20px;
    margin-bottom: 20px;
}

.footer-wrapper{
    border-top:5px solid #78a12f;
}

.type{
    margin-right:5px;
}

a{
    color: #78a12f;
    text-decoration: none;
}

.jumbotron{
    padding-top:4em;
    padding-bottom: 4em;
}

.breadcrumb {
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 5px;
    margin-top:5px;
}

.breadcrumb_wrapper{
    border-bottom:#ddd solid 1px;
}

.breadcrumb-item+.breadcrumb-item{
    padding-left:5px
}

.document_wrapper, .related_notices_wrapper{
    margin-top:20px
}

.emergency_content{
    margin-top:20px;
    margin-bottom: 20px;
}

.return_to a~a::before{
    content:', '
}

.sidebar{
    border-right:1px solid #ddd;
}


.info-cards-wrapper{
    padding-top:2em;
    padding-bottom: 2em;
}

.list_emergency:hover{
    background:#ddd;
    cursor:pointer;
}

.top-alerts .alert{
    border-radius: 0;
    border-top:0;
    text-align: center;
    padding:2px;
    margin-bottom: 0;
}

.top-alerts .alert p{
    margin-bottom: 0;
}

.top-alerts .alert:hover{
    cursor: pointer;
}

.alert-Emergency {
    background-color: #DA3037 !important;
    border-left: 5px solid #ffafb2;
    color:white
}

.alert-Information{
	border-left:5px solid #cce5ff;
	background-color:#49aac7;
    color:white
}

.btn-tnrd {
    background: #78a12f;
    border: none;
    color: white;
}

.btn-tnrd:hover {
    background: #0055a3;
    border: none;
    color: white;
}

.mailingList{
    background: #eaeaea;
}

footer{
    text-align: center;
}

footer .contact{
    text-align: center;
}

footer .logo img{
    max-width: 300px;
}

.social a{
    margin-right:10px;
    font-size:30px;
}

.navbar-collapse{
    flex-grow:0;
}

.type.bg-success{
    background:#78a22f !important;
}

.type.bg-warning{
    background:#f36f21 !important;
}

.type.bg-danger{
    background:#ba262b !important;
}

.type.bg-info{
    background:#3c90ba !important;
}

.status.bg-success{
    background: #1f419a !important;
}

.status.bg-warning{
    background:#626366 !important;
}

.mini {
    text-align: center;
}

@media (min-width: 768px) {

    footer{
        text-align: left;
    }
    footer .contact{
        text-align: right;
    }
    .content > div{
        padding-left: 20px;
    }

    .sidebar_wrapper{
        padding-right:20px
    }
    
    .col-sm-30 { width: 30%; }
    .col-sm-70 { width: 70%; }

    .mini{
        text-align: right;
    }

    
  }